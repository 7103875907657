.faq-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 120px 0;
  font-family: "Inter", sans-serif;
  padding: 0;
  box-sizing: border-box;
}

.faq-wrapper {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.faq-section.section--gray.cc-reasons,
.faq-section.section--gray.section--faq {
  padding-top: 0;
}

.faq-section.section--manifest {
  padding-bottom: 160px;
  padding-top: 100px;
}

.faq-section.section--faq {
  padding-top: 80px;
}

.faq-section-heading {
  font-size: 92px;
  letter-spacing: -0.045em;
  line-height: 1.1;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 890px;
}

.faq-section-heading.section-heading--faq {
  font-size: 80px;
  max-width: none;
  text-align: center;
  font-weight: 600;
}

.faq-section-header {
  margin-bottom: 142px;
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  text-align: left;
}

.faq-section-header.section-header--faq {
  margin-bottom: 64px;
}

.faq-container {
  background-color: white;
  color: black;
  border-radius: 20px;
  /* box-shadow: 0 5px 10px 0 rgb(0, 0, 0, 0.25); */
  border: 1px solid hsla(0, 0%, 51%, 0.16);
  margin: 20px 0;
}

.faq-question {
  font-size: 18px;
  font-weight: 600;
  padding: 30px 30px;
  display: flex;
  letter-spacing: -0.02em;
  line-height: 1.3;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.faq-question-text {
  max-width: 80%;
}

.faq-question-symbol {
  transition: transform 0.2s;
}

.faq-question-symbol::after {
  content: "\002B";
  font-size: 2rem;
  font-weight: 100;
}

.faq-question.active .faq-question-symbol {
  transform: rotate(45deg);
}

.faq-answercont {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-answer {
  font-size: 15px;
  font-weight: 500;
  padding: 0 30px 30px;
  letter-spacing: -0.015em;
  line-height: 1.35;
  max-width: 90%;
  opacity: 0.7;
}

.faq-question.active + .faq-answercont {
  max-height: 500px;
}

@media screen and (max-width: 991px) {
  .faq-section {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .faq-section.section--manifest {
    display: none;
  }

  .faq-section-heading {
    font-size: 72px;
    max-width: 650px;
  }

  .faq-section-heading.section-heading--faq {
    font-size: 56px;
  }

  .faq-section-header {
    margin-bottom: 80px;
  }

  .faq-section-heading-eyebrow {
    font-size: 26px;
  }
  .faq-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .faq-section-heading {
    font-size: 56px;
  }

  .faq-section-heading.section-heading--faq {
    font-size: 48px;
  }
  .faq-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .faq-section-heading {
    font-size: 52px;
  }

  .faq-section-heading.section-heading--faq {
    font-size: 44px;
  }
  .faq-wrapper {
    width: 100%;
  }
  .faq-question {
    font-size: 16px;
  }
  .faq-answer {
    font-size: 15px;
  }
  .faq-question-symbol::after {
    font-size: 1.8rem;
  }
  .faq-section {
    margin: 20px 0;
  }
}
