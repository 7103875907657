@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f7 /*#fafaff*/;
  font-weight: 400;
  overflow-x: hidden;
  font-size: 16px;
  margin: 0;
  min-height: 100%;
  line-height: 1.2;
}

main {
  background: #f5f5f7;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  transition: 0.2s ease-in;
}

ul,
ol {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
video {
  display: block;
  max-width: 100%;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
}

.btn-shadow {
  box-shadow: 0px 2px 5px rgba(160, 170, 180, 0.6);
}

.btn-shadow:hover {
  box-shadow: 0px 3px 8px rgba(160, 170, 180, 0.9);
}

.PrimaryBtn {
  padding: 12px 20px;
  border: none;
  background: rgb(57, 134, 250);
  color: #f6f6f6;
}

.SecondaryBtn {
  padding: 10px 18px;
  border: 2px solid rgb(57, 134, 250);
  color: #010606;
  margin-right: 1rem;
}

.PrimaryBtn:hover {
  background: rgb(18, 111, 250);
}

.SecondaryBtn:hover {
  background: #e7f1fa;
  color: #000;
}

.Container {
  max-width: 1400px;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 96%;
  margin-right: auto;
  margin-left: auto;
}

.Privacy-Container {
  max-width: 1000px;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.BigCard {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  background: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px; */
  border: 1px solid hsla(0, 0%, 51%, 0.16);
}

.AboutBio {
  text-align: justify;
  max-width: 1100px;
  margin: 0 auto;
  color: #1d1d1f;
  line-height: 1.6;
}

.PrivacyBio {
  text-align: justify;
  max-width: 860px;
  margin: 0 auto;
  color: #1d1d1f;
}

@media screen and (max-width: 768px) {
  .PrivacyBio {
    text-align: start;
  }
}

@media screen and (max-width: 768px) {
  .Container {
    width: 90%;
  }
}

@media screen and (max-width: 479px) {
  .Container {
    width: 90%;
  }
}

.tagline2 {
  text-align: left;
  max-width: 650px;
  margin: 0;
  color: #151418;
  line-height: 1.6;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 28px;
  color: #1d1d1f;
  font-weight: 600;
  margin-bottom: 2.5rem;
  opacity: 0.75;
  letter-spacing: -0.04em;
  line-height: 1.2;
  padding-left: 0.1em;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.ProjectWrapper {
  margin-top: -1rem;
}

/* Media for Container */
@media (max-width: 1400px) {
  .Container {
    padding: 0;
  }
}
